<template >
  <div>
    <topnav></topnav>
    <div class="page-wrap">
      <div class="big-top-img-head">
        <h1 class="txt160 serif big-top-head wider loading"> {{ $t('Why sell with us') }}</h1>
        <img src="../assets/who3.jpg" class="big-top-img who" />
        <div class="image-color"></div>
        <div class="image-grad"></div>

      </div>

      <div class="why-sec">
        <div class="why-box">
          <div class="why-box-head"><img src="../assets/why-icon-1.svg" />
            <p class="why-box-title serif">{{ $t('Your interest is our interest') }}
            </p>
          </div>


          <div v-if="$lang === 'en'" v-html="whyPage.yourInterestIsOurInterest"></div>
          <div v-else-if="$lang === 'fr'"
            v-html="whyPage.yourInterestIsOurInterestFrench || whyPage.yourInterestIsOurInterest"></div>

        </div>
        <div class="why-box">
          <div class="why-box-head"><img src="../assets/why-icon-2.svg" />
            <p class="why-box-title serif">{{ $t('Personal service') }}
            </p>
          </div>

          <div v-if="$lang === 'en'" v-html="whyPage.personalService"></div>
          <div v-else-if="$lang === 'fr'" v-html="whyPage.personalServiceFrench || whyPage.personalService"></div>

        </div>
        <div class="why-box">
          <div class="why-box-head "><img src="../assets/why-icon-3.svg" />
            <p class="why-box-title serif">
              {{ $t('Safer & quicker') }}
            </p>
          </div>


          <div v-if="$lang === 'en'" v-html="whyPage.saferQuicker"></div>
          <div v-else-if="$lang === 'fr'" v-html="whyPage.saferQuickerFrench || whyPage.saferQuicker"></div>

        </div>
        <div class="why-box">
          <div class="why-box-head"><img src="../assets/why-icon-4.svg" />
            <p class="why-box-title serif">
              {{ $t('No strings attached') }}
            </p>
          </div>

          <div v-if="$lang === 'en'" v-html="whyPage.noStringsAttached"></div>
          <div v-else-if="$lang === 'fr'" v-html="whyPage.noStringsAttachedFrench || whyPage.noStringsAttached"></div>
        </div>
      </div>
      <router-link to="/form" class="cta-btn">
        <p>
          {{ $t('Sell With Clarity') }}
        </p>
        <img src="@/assets/small-arrow.svg">
      </router-link>
      <div class="faq-sec">
        <h3 class="txt88 serif light">FAQ</h3>
        <div class="faq-list">
          <div v-if="$local.fAQ.length" class="faq" id="Faq">
            <faq v-for="f of $local.fAQ" class="faq-item" :key="f._id" :question="f[`question-${$lang}`]"
              :answer="f[`answer-${$lang}`]">
            </faq>
          </div>
        </div>

      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import Faq from '@/components/Faq.vue';
import { whyPage, fAQ } from '@/data/localData.json'
export default {
  components: {
    Topnav,
    Botfooter,
    Faq,
  },
  metaInfo() {
    return {
      ...this.seo({
        title: this.$lang === 'fr'? whyPage.titleFrench : whyPage.title,
        description: this.$lang === 'fr'?whyPage.meta_description_french: whyPage.meta_description
      }),
    };
  },
  computed: {
    $local() {
      return { whyPage, fAQ }
    },
    whyPage() {
      const pageID = "64d3638b2858cbc6ecf7b49d"
      return this.$local.whyPage.find(b => b._id === pageID)
    }
  },
  name: "Why sell with us"
};
</script>
