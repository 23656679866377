<template>
    <div :class="{ faqopen: faqOpen }">
        <div
            class="faq-icon"
            @click="faqOpen = !faqOpen"
        >
            <img
                src="../assets/faq-plus.svg"
                v-if="!faqOpen"
                class="faq-plus"
            />
            <img
                src="../assets/faq-min.svg"
                class="faq-min"
            />
        </div>
        <p
            @click="faqOpen = !faqOpen"
            class="faq-q serif"
        >{{ question }}</p>
        <div
            v-html="answer"
            class="faq-a"
        ></div>
    </div>
</template>
<script>
export default {
    name: "faq-item",
    data: () => ({
        faqOpen: false,
    }),
    props: {
        _id: { type: String, default: "" },
        question: { type: String, default: "" },
        answer: { type: String, default: "" },
    },
};
</script>

